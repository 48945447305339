$primary: #d4b98c;

@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: "Cerebri Sans";
  src: url(/build/fonts/Cerebri-Sans/CerebriSans-Regular.eot);
  src: url(/build/fonts/Cerebri-Sans/CerebriSans-Regular.ttf);
  font-weight: 500;
  font-style: normal;
}

.min-height-100 {
  min-height: 100%;
}

body {
  font: normal 1rem/1.5 'Cerebri Sans', sans-serif;
  background: #2F343A;
}

small {
  font-size: 1rem;
  font-weight: 400;
  color: #000;
}

.form-area {
  background: #FFF;
  padding: 24px 48px;
  border-radius: 5px;
}

.border-top-2 {
  border-top-width: 2px !important;
}

.form-text {
  display: block;
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: .85rem;
}

.font-weight-bold {
  font-weight: 500;
}

.form-text:hover {
  text-decoration: none;
}

.btn-primary {
  color: #FFF;
  letter-spacing: 1px;
  font-size: 14px;
  padding: 12px 0;
  text-transform: uppercase;
}